/* Title: styles.scss (src/components/Account) */

/* IMPORT */
@import '../../scss/base.scss';

/* CONTAINER */

#Account-container {
    height: auto;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
}




