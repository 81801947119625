/* styles.scss (src/components/Account/PasswordChange) */

@import '../../../scss/base.scss';

#PasswordChange-container {
    height: auto;
    width: 100%;
    box-sizing: border-box;
    padding: 4px;
    margin: 0;
    text-align: center;
}



