/* styles.scss (src/components/App) */

/* IMPORT */
@import '../../scss/base.scss';

/* CONTAINER */

#App-container {
    height: auto;
    min-height: 100vh;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 2px 0 2px 0;
    margin: 0;
    font-family: $b-font;
}

/* CLASS */

.App-link {
    flex: 0 1 48px;
    height: auto;
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;

    span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        font-size: 3em;
    }

    img {
        height: 36px;
        width: 36px;
        padding: 2px;
        margin: 0;
        border-radius: 8px;
    }
}

.App-logo {
    flex: 1 1 auto;
    height: auto;
    padding: 0 4px 0 0;
    text-align: right;
    font-size: 24px;
    font-weight: bold;
    font-family: "Nunito", sans-serif;
}

/* ID */

#App-header {
    order: 1;
    flex: 0 1 4em;
    width: 100%;
    padding: 0;
    margin: 0;
    border: $b-border;
    border-radius: 10px;
    background-image: $b-color-gradient;
}



#App-router {
    order: 2;
    flex: 1 1 auto;
    width: 100%;    

    /* Break Point for my css */
    /* Here and above is global page stuff  - see App-container */
    /* Here and below is local page stuff see Landing-container or Chart-container */
    /* Note: the router adds a dummy div and I can't find it for CSS */

}

#App-footer {
    order: 3;
    flex: 0 1 4em;
    width: 100%;
    border: $b-border;
    border-radius: 10px;
    background-image: $b-color-gradient;

    span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        font-size: 3em;
    }

}

#App-header-auth {
    
}

#App-header-authLinks {
    height: auto;
    flex: 0 1 192px;
    padding: 0;
    margin: 0;
}

#App-SignOut {
    height: auto;
    flex: 0 1 48px;
    padding: 0;
    margin: 0;
}

#App-SignOut:hover {
    cursor: pointer;
}



#App-header-noauth {
    
}

#App-span-img {
    flex: 0 1 48px;
    height: auto;
    width: auto;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

#App-span-tm {
    flex: 1 1 auto;
    height: auto;
    padding: 0 0 0 0;
    margin: 0;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    font-family: "Nunito", sans-serif;
}









