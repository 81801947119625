/* styles.css (src/components/Session) */

/* IMPORT */
@import '../../scss/base.scss';

/* CONTAINER */
#Verify-container {
    
}

/* CLASS */
.Verify-message {
    flex: 1 1 auto;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    font-size: 2em;
    padding: 0;
    margin: 2em 0 0 0;
}


/* ID */
#Verify-reset {
    flex: 1 1 auto;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 2em 0 0 0;
}

#Verify-resetMessage {
    height: auto;
    flex: 1 1 50%;
    margin: 0;
    padding: 0 1em 0 0;
    font-size: 2em;
    text-align: right;
    font-weight: bold;
}

#Verify-resetButton {
    height: auto;
    flex: 1 1 50%;
    margin: 0;
    padding: 0 0 0 1em;
    text-align: left;
}













    

