/* styles.scss (src/components/ChartNew/LeftPanel) */

@import '../../../scss/base.scss';



#LeftPanel-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column nowrap;
    box-sizing: border-box;
    padding: 0;
    margin: 0;

    /* HTML TAGS */
    h3 {
        font-size: 2.5em;
    }
}

#LeftPanel-ChartInput {
    
}

#LeftPanel-MyTileBoard {
    
}  










    

