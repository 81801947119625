/* styles.scss (src/components/Landing) */

/* IMPORT */
@import '../../scss/base.scss';

/* CONTAINER */

#Landing-container {

    @include for-phone-only {       
    }
    @include for-tablet-portrait-only {        
    }
    @include for-tablet-portrait-up {
    }
    @include for-tablet-landscape-only {
    }
    @include for-tablet-landscape-up {
    }
    @include for-desktop-only {
    }
    @include for-desktop-up {
    }
    @include for-big-desktop-up {
    }

    width: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 0 4px 0;
    margin: 0;
    text-align: center;
    font-family: $b-font;
    color: $b-color-text;
    /* HTML TAGS */
    h3 {
        font-family: $b-font-heading;
        font-size: 3.5em;
    }

    h4 {
        font-family: $b-font-heading;
        font-size: 2.0em;
    }
}

/* CLASSES */



.Landing-bigButton {
    border: $b-border-button;
    border-radius: 16px;
    background-color: $b-color-pop;
    height: auto;
    width: 50%;
    padding: 6px;
    margin: 0 0 0 0;
    text-align: center;
    font-family: $b-font-heading;
    font-size: 2.5em;
    color: $b-color-text;
}

.Landing-bigButton:hover {
    border: $b-border-hover;
}

/* ID */

#Landing-LandingConsole {
    flex: 1 1 35%;
    width: 100%;  
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

#Landing-main {

    @include for-phone-only {
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
    }

    @include for-tablet-portrait-only {
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
    }

    @include for-tablet-landscape-up {
        flex-flow: row nowrap;
        justify-content: center;
        align-items: stretch;
    }

    flex: 5 1 65%;
    width: 100%;
    box-sizing: border-box;
    display: flex;

}

#Landing-logo {
    @include for-phone-only {
        width: 100%;
        flex: 1 1 auto;
    }

    @include for-tablet-portrait-only {
        width: 100%;
        flex: 1 1 auto;
    }

    @include for-tablet-landscape-up {
        height: auto;
        flex: 1 1 30%;
    }
    
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    box-sizing: border-box;
    padding: 0 0 0 0;
    margin: 0;
    text-align: right;
    font-size: 20em;
    font-family: $b-font;
    font-weight: bold;

    p {
        padding: 0;
        margin: 0;
    }
}

#Landing-consumer {
    @include for-phone-only {
        width: 100%;
        flex: 1 1 auto;
    }

    @include for-tablet-portrait-only {
        width: 100%;
        flex: 1 1 auto;
    }

    @include for-tablet-landscape-up {
        height: auto;
        flex: 1 1 70%;
    }

    
}

#Landing-auth {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 4px 2em 4px 2em;
    margin: 0;
}

#Landing-noauth {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 4px 2em 4px 2em;
    margin: 0;
}

#Landing-SiteMap {
    height: auto;
    flex: 1 1 40%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 0 0 0 0;
    border: $b-border-panel;
    border-radius: 1.6em;
    background-image: $b-color-gradient;
}

#Landing-welcome {
    height: auto;
    flex: 1 1 60%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 0 0 0 0;
    font-family: $b-font;
    font-size: 2.5em;
    color: $b-color-text;
}
#Landing-intro {
    height: auto;
    flex: 1 1 60%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 1em 0 0 0;
    margin: 0 14em 0 0;
    font-family: $b-font;
    font-size: 2.5em;
    color: $b-color-text;

    p {
        flex: 1 1 auto;
        width: 100%;
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        text-align: center;
        font-weight: bold;
    }

    iframe {
        flex: 1 1 auto;
        width: auto;
        border: none;
        padding: 0;
        margin: 0.5em 0 0 0;
    }
}

#Landing-message {
    font-size: 60%;
    font-weight: normal;
}

#Landing-login {
    height: auto;
    flex: 1 1 40%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 0 4em 0 0;
    border: $b-border-panel;
    border-radius: 1.6em;
    background-image: $b-color-gradient;
}

#Landing-social {

    button {
        padding: 6px;
        margin: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: $b-border-button;
        border-radius: 16px;
        background-color: $b-color-pop;
    }

    button:hover {        
        border: $b-border-hover;        
        cursor: pointer;
    }

    button:active {        
        transform: translateY(2px);
    }

    button:focus {
        outline: $b-outline;
    }

    img {
        height: 32px;
        width: 32px;
    }
}

#Landing-bigPonzi {
    order: 1;
    flex: 0 1 auto;
    max-width: 100%;
    box-sizing: border-box;
    border-radius: 3em;
}





