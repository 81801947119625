/* styles.scss (src/components/Chart */

/* IMPORT */
@import '../../scss/base.scss';

/* CONTAINERS */
#Chart-container {

    @include for-phone-only {
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
    }

    @include for-tablet-portrait-only {
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
    }

    @include for-tablet-landscape-up {
        flex-flow: row nowrap;
        justify-content: space-around;
        align-items: flex-start;
    }

    height: auto;
    width: 100%;
    display: flex;
    padding: 4px 0 4px 0;
    margin: auto;
    /* HTML TAG */
}
#Chart-context {
    height: auto;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    text-align: center;
}

/* Global App sizing is done in the parent */
/* This file will control only the current page and children */
/* Set page padding here, then use width 100% with border-box for children */

/* CLASS */

.Chart-sidePanel {
    height: auto;
    flex: 1 0 10%;
    box-sizing: border-box;
    padding: 0 1px 0 1px;
    margin: 0;
    border: $b-border-panel;
    border-radius: 10px;
    background-image: $b-color-gradient;
}

/* ID */ 

#Chart-LeftPanel {

}

#Chart-ChartOutput {
    height: auto;
    flex: 0 1 80%;
    box-sizing: border-box;
    padding: 8px 2px 0 2px;
    margin: 0;
    border: none;
}

#Chart-RightPanel {

}    










    

