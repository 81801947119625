/* styles.scss (src/components/Landing/SignInGoogle) */

@import '../../../scss/base.scss';

#Google-container {   
   text-align: center;
}



