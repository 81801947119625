/* styles.scss (src/componenets/ChartNew/RightPanel/GiphySearch) */

/* IMPORT */

@import '../../../../scss/base.scss';

/* CONTAINER */
.GiphySearch-container {
    height: auto;
    width: 100%;
    box-sizing: border-box;
    padding: 4px;
    margin: 0;
    text-align: center;
}

/* GLOBAL */



/* CLASS */



/* ID */