/* styles.scss (src/components/Landing/SignUp) */

@import '../../../scss/base.scss';


#SignUp-container {
    height: auto;
    width: 100%;
    box-sizing: border-box;
    padding: 4px;
    margin: 0;
}

.SignUp-Form {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.SignUp-formRow {
    flex: 1 1 auto;
    width: 100%;
    box-sizing: border-box;
    padding: 2px 0 2px 0;
    margin: 0;
    text-align: center;
}





