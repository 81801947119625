/* Title: styles.scss (src/components/ChartNew/RightPanel/ChartSettings/PopMenu) */

/* IMPORT */
@import '../../../../../scss/base.scss';

/* CONTAINER */

.PopMenu-container {
    height: auto;
    width: auto;
    min-width: 2.4em;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0 4px 0 4px;
    margin: 0;
    font-size: 1.6em;

    button {
        border: $b-border-button;
        border-radius: 14px;
        background-color: $b-color-dark;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
        margin: 0;

        span {
            padding: 2px;
            margin: 0;
        }
    }

    button:hover {
        background-color: $b-color;
        border: $b-border-hover;
        cursor: pointer;
    }

    button:active {
        transform: translateY(2px);
    }

    button:focus {
        outline: $b-border-focus;
    }

    form {
        text-align: center;
    }

    select {
        padding: 2px;
        margin: 0;
        border-radius: 40%;
    }

    option {
    }
}

/* HTML TAGS */

/* CLASS */

.PopMenu-row {
    flex: 1 1 auto;
    width: 100%;
    box-sizing: border-box;
    padding: 4px 0 4px 0;
    margin: 0;
}
    
/* ID (may not work with 3rd party, try Class first */

/* COMPONENT CSS */

.PopMenu-component-content {
    background: black;

}

