/* styles.scss (src/componenets/ChartNew/ChartOutput/ChartGif) */

/* IMPORT */
@import '../../../../scss/base.scss';

/* CONTAINER */

.Cellophane-drag {
    display: inline-block;
    position: relative;
}

.Cellophane-drag:after {
    content: "";
    position: absolute;
    z-index: +50;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

/* HTML TAGS */

/* CLASS */

.ChartGif-button-wrap {
    height: auto;
    flex: 1 1 45%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.ChartGif-button {
    position: relative;
    z-index: +1;
    height: 2em;
    width: 2em;
    border: $b-border-button;
    border-radius: 14px;
    background-color: $b-color-dark;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    margin: 0;
    font-size: 1.8em;
    opacity: 0;

    span {
        padding: 0;
        margin: 0;
    }
}

.ChartGif-button:hover {
    background-color: $b-color-pop;
    border: $b-border-hover;
    cursor: pointer;
    opacity: 1;
}

.ChartGif-button:active {
    transform: translateY(2px);
}

.ChartGif-button:focus {
    outline: $b-border-focus;
}

.react-draggable {
    height: auto;
    flex: 1 1 auto;
    min-width: 18%;
    max-width: 25%;
    text-align: center;
}

/* ID (don't use b/c mapped component)*/