/* styles.scss (src/componenets/ChartNew/ChartOutput/InfoSheet) */

/* IMPORT */
@import '../../../../scss/base.scss';

/* CONTAINER */

#InfoSheet-container {
    height: auto;
    width: 96%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 6px;
    margin: auto;
    background-color: white;
    border: $b-border-panel;
    border-radius: 2em;

    /* HTML TAGS */

    a {
        padding: 0;
        margin: 0;
        font-family: $b-font;
        font-size: 2.0em;
        color: $b-color-text-dark;
        text-align: left;
    }

    a:visited {
        color: #9123c4;
    }

    a:hover {
        color: #3a3ad6;
    }


    h2 {
        font-size: 4.0em;
    }

    h3 {
        font-size: 3.0em;
    }
}


/* CLASS */

/* ID */

#InfoSheet-headerO{
    flex: 1 1 auto;
    width: 100%;
    box-sizing: border-box;   
    padding: 6px;
    margin: 0;
    font-family: $b-font-heading;
    border: $b-border-panel;
    border-radius: 2em;
    background-image: $b-color-gradient;
}

#InfoSheet-header {
    height: auto;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
}

#InfoSheet-headEmoji {
    flex: 1 1 30%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    font-size: 120%;
    /* emphasize emoji */
}

#InfoSheet-headTitle {
    flex: 1 1 70%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

#InfoSheet-body {
    flex: 1 1 auto;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    padding: 6px;
    margin: 0;

}

#InfoSheet-quote {
    order: 1;
    flex: 1 1 22%;
    height: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 0;
}

#InfoSheet-bubble {
    height: auto;
    width: 92%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    font-size: 3.2em;
    border-radius: 12%;
    border: $b-border-panel;

    p {
        height: auto;
        width: 96%;
        box-sizing: border-box;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 6%;
        padding: 0;
        margin: 0;
    }

    span {
        padding: 4px;
        margin: 0;
        font-size: 60%;
    }
}

#InfoSheet-summary {
    order: 2;
    flex: 3 1 50%;
    height: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px 0 20px;
    margin: 0;

    div {
        flex: 1 1 auto;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
    }

    p {
        height: auto;
        width: 100%;
        text-align: left;
        font-size: 2em;
    }

    ul {
        height: auto;
        width: 100%;
        box-sizing: border-box;
        list-style-type: disc;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 0.9em;
    }

    li {
        text-align: left;
        font-size: 2em;
    }
}

.InfoSheet-catHead {
    font-size: 3em;
}

#InfoSheet-links {
    order: 3;
    flex: 1 1 28%;
    height: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    margin: 0;

    p {
        height: auto;
        width: 100%;
        text-align: left;
        font-size: 2em;
        padding: 0;
        margin: 0;
    }
}


    






