/* styles.css (src/components/ChartNew/ChartOutput/BarTip) */

/* IMPORT */
@import '../../../../scss/base.scss';

/* CONTAINER */
#BarTip-container {
    /* HTML TAG */
}

/* CLASS */

.BarTip-content {
    height: auto;
    width: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    margin: 0;
    border: $b-border-panel;
    border-radius: 10px;
    background-image: $b-color-gradient;
    font-family: $b-font-number;
    font-size: 2em;
    font-weight: bold;
    color: $b-color-text-dark;
    opacity: 0.8;
}

.BarTip-row {
    flex: 1 1 auto;
    width: 100%;
    box-sizing: border-box;
    padding: 4px;
    margin: 0;
    border: 2px inset black;
    border-radius: 6px;
    background-color: $b-color;
}


/* ID */



