/* styles.scss (src/components/Landing/SiteMap) */

@import '../../../scss/base.scss';

#SiteMap-container { 
    height: auto;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 0;
    /*border: $b-border-panel;
    border-radius: 1.6em;
    background-image: $b-color-gradient;*/
}

#SiteMap-header {
    
   
}

.SiteMap-links {
    height: auto;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    

    

    p {
        padding: 0;
        margin: auto;
        font-family: "Luckiest Guy", cursive;
        font-size: 2.0em;
        font-weight: bold;
    }
}

.SiteMap-linkRow {
    flex: 1 1 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.SiteMap-linkRow:hover {
    cursor: pointer;
}

.SiteMap-emoji {
    flex: 1 1 30%;
    padding: 0;
    margin: 0;
    text-align: left;
    font-size: 6em;
}

.SiteMap-emoLab {
    flex: 5 1 70%;
    padding: 0 4px 0 10px;
    margin: 0;
    text-align: left;
    font-family: $b-font-heading;
    font-size: 3em;
    font-weight: bold;
}
