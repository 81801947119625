/* styles.scss (src/components/ChartNew/RightPanel) */

/* IMPORT */

@import '../../../scss/base.scss';

/* CONTAINER */

#RightPanel-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column nowrap;
    box-sizing: border-box;
    padding: 0;
    margin: 0;

    /* HTML TAGS */
    h3 {
        font-size: 2.5em;
    }

    h4 {
        text-align: left;
        font-size: 2.0em;
        padding: 0 0 0 0.4em;
    }
}


/* CLASS */


/* ID */





