/* styles.scss (src/components/ChartNew/LeftPanel/GifPanel/GifFlip) */

/* IMPORT */
@import '../../../../../scss/base.scss';

/* CONTAINER */

.GifFlip-container {
    height: auto;
    width: 100%;
    padding: 0;
    margin: 0 0 4px 0;
    /* LOCAL HTML TAG */
    iframe {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        border: none;
    }

    img {
        float: left;
        height: 32px;
        width: 32px;
        margin: 0 2px 0 0;
        padding: 0;
    }

    button {
        width: 30px;
        height: 30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0 0 0 0;
        margin: 0;
        border: $b-border-button;
        border-radius: 50%;
        background-color: #81c8f7;
        font-family: "Courier New", Courier, monospace;
        font-size: 1.6em;

    }

    button:hover {
        background-color: $b-color;
    }

    a {
        padding: 0;
        margin: 0;
        font-family: $b-font;
        font-size: 1.4em;
        font-weight: bold;
        text-decoration: none;
        overflow-wrap: break-word;
        color: $b-color-text-dark;
    }

    a:visited {
        color: #9123c4;
    }

    a:hover {
        color: #3a3ad6;
    }
}

/* CLASS */

.GifFlip-card-outer {
    height: 100%;
    width: 100%;    
    padding: 0;
    margin: 0;
}

.GifFlip-border {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 2px;
    margin: auto;
    text-align: center;
    border: 2px solid #1A5190;
    border-radius: 6px;
    background-image: $b-color-gradient;
}

.GifFlip-cellophane {

    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
}

.GifFlip-cellophane:after {
    content: "";
    display: block;
    position: absolute;
    z-index: +99;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    left: 0;
    top: 0;
}

.GifFlip-cellophane:hover {
    cursor: pointer;
}

.GifFlip-cardBack-inner {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
}

.GifFlip-link {
    flex: 1 1 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: left;
}


.GifFlip-footer {
    flex: 0 0 0px;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;

    div {        
        flex: 0 0 34px;
        padding: 0;
        margin: 0;
    }

}



/* ID (DON'T USE, MAPPED COMPONENT) */



