/* Title: styles.scss (src/components/SignOut) */

/* IMPORT */
@import '../../scss/base.scss';

/* CONTAINER */
#SignOut-container {
    width: 100%;
    height: auto;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 3em;
}




