/* styles.css (src/components/ChartNew/ChartOutput/LineTip) */

/* IMPORT */
@import '../../../../scss/base.scss';

/* CONTAINER */
#LineTip-container {
    /* HTML TAG */
}

/* CLASS */

.LineTip-row {
    flex: 1 1 auto;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    margin: 0 0 6px 0;
    border: 2px inset black;
    border-radius: 6px;
    background-color: $b-color;
}

.LineTip-emoji {
    flex: 1 1 20%;
    text-align: left;
}

.LineTip-number {
    flex: 1 1 80%;
    text-align: right;
}

/* ID */

#LineTip-content {
    height: auto;
    width: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    margin: 0;
    border: $b-border-panel;
    border-radius: 10px;
    background-image: $b-color-gradient;
    font-family: $b-font-number;
    font-size: 2em;
    font-weight: bold;
    color: $b-color-text-dark;
    opacity: 0.8;
}

