/* styles.scss (src/components/Landing/PasswordForget) */

@import '../../../scss/base.scss';

#PasswordForget-container {
    height: auto;
    width: 100%;
    box-sizing: border-box;
    padding: 4px;
    margin: 0;
    text-align: center;
}



