/* styles.scss (src/components/Landing/LandingConsole) */

/* IMPORT */
@import '../../../scss/base.scss';

/* CONTAINER */
#LandingConsole-container {
    height: auto;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: $b-font-number;
    color: $b-color-text;
    /* HTML TAGS */
}

/* ID */

#LandingConsole-content {
    height: auto;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    border: $b-border-panel;
    background-image: url('../../../img/scratch.jpg');
}

#LandingConsole-marquee {
    height: auto;
    width: 3580px;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 0;
}

/* CLASS */

.LandingConsole-quoteTile {
    height: auto;    
    flex: 1 1 150px;
    min-width: 150px;
    max-width: 150px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: column nowrap;
    padding: 0;
    margin: 0;
    border: $b-border-panel;
    border-radius: 0.5em;
    background-image: $b-color-gradient;
    font-size: 2.2em;

    a {
        height: auto;
        width: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        text-decoration: none;
    }

    div {
        flex: 1 1 auto;
        box-sizing: border-box;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0 0 2px 0;
    }
}

.LandingConsole-ticker {
    width: 100%;
}

.LandingConsole-price {
    width: 100%;
}

.LandingConsole-change {
    width: 90%;
    font-size: 75%;
    border-radius: 10%;
}

.LandingConsole-volume {
    width: 100%;
    font-size: 75%;
}

/* ANIMATION */

@keyframes marquee-move {
    0% {
        transform: translate(0, 0);
    }

    40% {
        transform: translate(-50%, 0);
    }

    50% {
        transform: translate(-50%, 0);
    }

    90% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(0, 0);
    }
}
/* Animation on/off is set in js inline */
