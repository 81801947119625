/* styles.scss (src/componenets/ChartNew/ChartOutput/ChartGif/GifPopup) */

/* IMPORT */
@import '../../../../../scss/base.scss';

/* CONTAINER */

.GifPop-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}
/* use class, mapped component */

/* HTML TAGS */


/* CLASS */

.GifPop-emoji {
    height: auto;
    flex: 1 1 auto;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 2.0em;

    span {
        width: 4.2em;
        text-align: center;
    }
}

.GifPop-Knob-wrap {
    height: auto;
    flex: 1 1 auto;
    display: inline-flex;
    justify-content: center;
    align-items: center;

}

/* ID (don't use b/c mapped component)*/

/* COMPONENT CSS */

.popup-content {
    background-image: $b-color-gradient;
    width: 20em;
    
}
[role='tooltip'].popup-content {
    width: auto;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}