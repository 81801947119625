/* styles.scss (src/componenets/ChartNew/LeftPanel/ChartInput) */

/* IMPORT */
@import '../../../../scss/base.scss';

/* CONTAINER */

#ChartInput-container {
    height: auto;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 0;
}


/* CLASS */

.ChartInput-int {
    flex: 1 1 auto;
    width: 100%;
    padding: 2px 0 2px 0;
    margin: auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    font-size: 2.0em;

    span {
        height: auto;
        flex: 1 1 auto;
        text-align: center;
        font-size: 50%;
    }

    button {
        height: auto;
        flex: 1 1 auto;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: $b-border-button;
        border-radius: 10px;
        background-color: $b-color-dark;
        padding: 3px;
        text-align: center;
        font-family: "Courier New", Courier, monospace;
        font-weight: bold;
        font-size: 75%;
    }

    button:hover {
        background-color: $b-color;
        border: $b-border-hover;
        cursor: pointer;
    }

    button:active {
        transform: translateY(2px);
    }

    button:focus {
        outline: $b-border-focus;
    }
}

.ChartInput-result {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2px 0 2px 0;
    margin: 0 0 2px 0;
    border: $b-border-button;
    border-radius: 0.5em;
    font-family: $b-font;    
    font-weight: bold;
    color: $b-color-text;
    background-color: $b-color-contrast;

    span {      
        padding: 2px;
        text-align: left;
        overflow: hidden;
    }
}

.ChartInput-result:hover {
    cursor: pointer;
}

.ChartInput-result-tick {
    flex: 1 1 30%;
    font-size: 1.4em;
}

.ChartInput-result-comp {
    flex: 1 1 70%;
    font-size: 1.1em;
}

/* ID */


#ChartInput-header {
   padding: 2px 0 6px 0;
   margin: 0;   
}

#ChartInput-Formik {


}

#ChartInput-tick {
    padding: 4px 0 4px 0;
    margin: 0;
}

#ChartInput-tick-buttonWrap {
    padding: 0 2px 0 2px;
    margin: 0;
}

#ChartInput-start {
    padding: 2px 0 2px 0;
    margin: auto;
}

#ChartInput-jump {
    padding: 2px 0 2px 0;
    margin: auto;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2.0em;

    span {
        height: auto;
        flex: 1 1 auto;
    }

    button {
        height: auto;
        flex: 1 1 auto;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: $b-border-button;
        border-radius: 10px;
        background-color: $b-color-dark;
        padding: 3px;
        margin: 0 1px 0 1px;
        text-align: center;
        font-family: Courier New, Courier, monospace;
        font-weight: bold;
        font-size: 75%;
    }

    button:hover {
        background-color: $b-color;
        border: $b-border-hover;
        cursor: pointer;
    }

    button:active {
        transform: translateY(2px);
    }

    button:focus {
        outline: $b-border-focus;
    }
}

#ChartInput-end {
    padding: 2px 0 2px 0;
    margin: auto;
}

#ChartInput-live {
    height: auto;
    width: 100%;
    padding: 0 2px 0 2px;
    margin: 0;
}





    






