/* styles.scss (src/components/Landing/SignIn) */

@import '../../../scss/base.scss';

#SignIn-container {
    height: auto;
    width: 100%;
    box-sizing: border-box;
    padding: 4px;
    margin: 0;
    text-align: center;
}

#SignIn-reset:hover {
    cursor: pointer;
}



