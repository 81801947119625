/* styles.css (src/components/ChartNew/ChartOutput) */

/* IMPORT */

@import '../../../scss/base.scss';

/* CONTAINER */

#ChartOutput-container {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    /* HTML TAG */
}

/* CLASS */

.ChartOutput-recharts {
    flex: 1 1 auto;
    width: 100%;
    overflow-y: hidden;
    margin: 0;
    padding: 0;
}
/* <ResponsiveContainer /> is 98% of this (100% locks screen) */

/* ID */

#ChartOutput-content {
    height: auto;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items:center;
}

#ChartOutput-header {
    position: relative;
    z-index: +1;
    flex: 0 0 0px;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
    
}

#ChartOutput-InfoSheet {
    flex: 1 1 auto;
    width: 100%;
    padding: 0;
    margin: 0;
}

#ChartOutput-clearGifs {
    height: 0px;
    flex: 1 1 20%;
    padding: 0;
    margin: 0;
    text-align: center;

    button {
        height: 2.0em;
        flex: 0 1 25%;
        border: $b-border-button;
        border-radius: 14px;
        background-color: $b-color-dark;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0 2px 0 2px;
        font-family: $b-font;
        font-size: 1.8em;
        font-weight: bold;
        opacity: 0.2;
    }

    button:hover {
        background-color: $b-color;
        border: $b-border-hover;
        cursor: pointer;
        opacity: 1;
    }

    button:active {
        transform: translateY(2px);
    }

    button:focus {
        outline: $b-border-focus;
    }
}

#ChartOutput-tickLink {
    height: 0px;
    flex: 1 1 60%;
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 10em;
    opacity: 0.5;

    a {
        text-decoration: none;
    }
}

#ChartOutput-screen {
    height: 0px;
    flex: 1 1 20%;
    padding: 0 2em 0 0;
    margin: 0;
    display: inline-flex;
    justify-content: flex-end;
    align-items: flex-start;

    button {
        height: 2.0em;
        flex: 0 1 10%;
        border: $b-border-button;
        border-radius: 14px;
        background-color: $b-color-dark;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0 2px 0 2px;
        font-size: 1.8em;
        font-weight: bold;
        opacity: 0.2;
    }

    button:hover {
        background-color: $b-color;
        border: $b-border-hover;
        cursor: pointer;
        opacity: 1;
    }

    button:active {
        transform: translateY(2px);
    }

    button:focus {
        outline: $b-border-focus;
    }
}

#ChartOutput-gifs {
    flex: 1 1 auto;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

