/* styles.scss (src/components/ChartNew/RightPanel/ChartSettings */

/* IMPORT */
@import '../../../../scss/base.scss';

/* CONTAINER */

#ChartSettings-container {
    flex: 1 1 auto;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 0;
}

/* CLASS */

.ChartSettings-row {
    flex: 1 1 auto;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    padding: 4px 0 4px 0;
    margin: 0;
}

/* ID */

