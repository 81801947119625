/* styles.scss (src/componenents/ChartNew/RightPanel/GifFixed) */

/* IMPORT */

@import '../../../../scss/base.scss';

/* CONTAINER */
.GifFixed-container {
    flex: 1 1 auto;
    width: 100%;
    padding: 0;
    margin: 0 0 4px 0;
    /* LOCAL HTML TAG */
    iframe {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        border: none;
    }

    button {
        position: relative;
        z-index: +20;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        border: $b-border-button;
        border-radius: 50%;
        background-color: #81c8f7;
        font-family: "Courier New", Courier, monospace;
        font-size: 1.6em;
        opacity: 0.1;
    }

    button:hover {
        background-color: $b-color;
        opacity: 1.0;
    }

    a {
        position: relative;
        z-index: +20;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        border: $b-border-button;
        border-radius: 16px;
        background-color: #81c8f7;
        font-family: "Courier New", Courier, monospace;
        font-size: 1.6em;
        font-weight: bold;
        color: #1A5190;
        opacity: 0.1;
    }

    a:hover {
        opacity: 1.0;
    }

    a:active {
        transform: $b-button-active;
    }

    img {
        border-radius: 50%;
    }
}

/* CLASS */

.GifFixed-outer {
    /*height: 100%; >> set inline */
    width: 100%;
    padding: 0;
    margin: 0;
    
}

.GifFixed-border {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 2px;
    margin: auto;
    text-align: center;
    border: 2px solid #1A5190;
    border-radius: 6px;
    background-image: $b-color-gradient;
}

.GifFixed-cellophane {
    position: relative;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
}

.GifFixed-cellophane:after {
    content: "";
    display: block;
    position: absolute;
    z-index: +10;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    left: 0;
    top: 0;
}

.GifFixed-footer {
    height: 0px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;

    div {
        flex: 0 0 34px;
        padding: 0;
        margin: 0;
    }
}


/* ID - not used (mapped component) */







